@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
    --primary-color: rgb(84, 156, 206);
    --primary-color-light: rgba(84, 156, 206, .6);

    --secundary-color: rgb(254, 148, 46);
    --secundary-color-light: rgba(254, 148, 46, .6);

    --body-color: rgb(234, 239, 242);
}

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}


html {
    box-sizing: border-box;
    font-size: 62.5%;
}

body {
    background-color: var(--body-color);
    font-family: 'Roboto', sans-serif;
}

div, a, i, span {
font-size: 1.6rem;
}

i {
    font-size: inherit;
}

button, input[type="submit"] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

li {
    list-style-type: none;
}


.ajuste-icon-direita i::after {
    transform: translateY(-50%) translateX(-55%)!important;
}

.ajuste-icon-direita-2 i::after {
    transform: translateY(-50%) translateX(-60%)!important;
}

.ajuste-icon-esquerda i::after {
    transform: translateY(-50%) translateX(-45%)!important;
}


.ajust-icon-direita::after {
    transform: translateY(-50%) translateX(-55%)!important;
}

.ajust-icon-direita-2::after {
    transform: translateY(-50%) translateX(-60%)!important;
}

.ajust-icon-esquerda::after {
    transform: translateY(-50%) translateX(-45%)!important;
}





.react-datepicker {
    font-size: 1.2rem!important;
}
.react-datepicker__header {
    padding-top: 0.8rem;
}
.react-datepicker__month {
    margin: 0.4rem 1rem;
}
.react-datepicker__day-name, .react-datepicker__day {
    width: 2.4rem!important;
    line-height: 2.4rem!important;
    margin: 0.300rem;
    font-size: 1.2rem!important;

}
.react-datepicker__current-month {
    font-size: 1.2rem!important;
}
.react-datepicker__navigation {
    top: 1rem;
    line-height: 1.7rem;
    border: 0.45rem solid transparent;
}
.react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1rem;
}
.react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1rem;
}

.react-datepicker__day--outside-month {

}
